.homePage{
    width: 100%;
    height: 100%;
}

/* Banner */

.banner{
    height: 100%;    
    width: 100%;
    /* height: 200px; */
    overflow: hidden;
    text-align: center;   
    position: relative;
     /* Starting image for when the video is loading  */
    background-image: url("../../Media/bannerImageBeforeVideo.jpg");
    background-position: center;
    object-fit: cover;
    background-size: cover;  /* This ensures the background image covers the entire area */
    background-repeat: no-repeat;
    /* background-color: orange; */
}
.bannerVideo{
    object-position: 30%;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    display: inline-block;
}
.bannerText{
    width: 90%;
    position: absolute;
    bottom: 10px;   
    left: 5%;
    text-align: center;  
    color: white; 
    font-size: 30px;
    display: inline-block;
    text-shadow: 1px 1px 2px black;
}
.bannerText1Desktop{
    font-size: 50px;
    display: inline-block;   
}
@media screen and (max-width: 650px){
    .bannerText1Desktop{
        font-size: 40px;
    }
    .bannerText2Desktop{
        font-size: 30px;
    }
}
.bannerText2{
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
}
.bannerText2Desktop{
    font-size: 30px;
    display: inline-block;  
}
.bannerButton{    
    padding: 15px 40px;    
    background-color: #D65E4D;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 500ms;
    /* margin: 36px; */
    margin: 10px 30px;
    display: inline-block;    
    font-size: 16px;
    text-shadow: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.493);
    background-image: linear-gradient(rgba(255, 188, 2, 0.3), rgba(255, 255, 255, 0), rgba(105, 74, 0, 0.274));
    width: 300px;
}
.bannerButton:hover{
    background-color: rgb(37, 10, 100);
    box-shadow: 0 0 20px #f3a126b0;
}
@media screen and (max-width: 400px){
    .bannerButton{
        width: 95%;
        margin: 10px 2.5%;
    }
}
.goldLetters{
    color: #FFE170;
    /* background-color: rgba(248, 217, 103, 0.14); */
    box-shadow: 0 0 20px #fcdb661c;
    border-radius: 25%;
    padding: -10px 10px;
    background-image: radial-gradient(rgba(255, 217, 0, 0.31), rgba(255, 217, 0, 0.1), rgba(94, 90, 66, 0))
}

/* Text Sectoins */

.butterflySection{
    background-attachment: fixed;    
    color: white;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    /* height: 260px; */
}
.butterflySectionDesktop{
    padding: 50px 80px;
    padding-bottom: 80px;

}
.butterflySectionTitleArea{
    padding-top: 20px;
}
.orangeButterflySection{
    background: url("../../Media/butterflyOrange.jpg");
    background-attachment: fixed;    
}
.purpleButterflySection{
    background: url("../../Media/butterflyPurple.jpg");
    background-attachment: fixed;    
    padding-top: 0px;
    background-size: contain;
    background-attachment: fixed;
}
.scrollToBox{
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding: 0;
    margin: 0;
    z-index: -10;
}
.infoTitle{
    font-size: 35px;
}
.infoTitleDesktop{
    padding-bottom: 20px;
}
.infoText{
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: justify;
    max-width: 800px;
    display: inline-block;
}

/* Circle Info Area */

.noBackgroundSection{
    min-height: 200px;
    width: 100%;
    background-color: #F7F7F7;
}
.circleInfoArea{
    padding: 20px 0;
}
.circleInfoAreaDesktop{
    padding: 40px 0;
}
.circleInfoBox{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    justify-content: center;
    /* min-height: 16em; */
    position: relative;
    text-align: left;
    text-align: center;
    display: inline-block;
}
.circleInfoBox img{
    height: 18em;
    width: 18em;
    border-radius: 50%;
}
/* Contains the circle and text box */
.circleInfoBoxDesktop{
    min-height: 380px;
    max-width: 1200px;  
    position: relative;
    padding-right: 20px;
}

.circleInfoBoxDesktop img{
    height: 25em;
    width: 25em;
    float: left;
    margin-left: 40px;
    margin-top: -40px;
    margin-bottom: -40px;
}
.circleInfoBoxDesktopRight img{
    height: 25em;
    width: 25em;
    float: right;
    margin-left: 40px;
}
.circleInfoTextBox{
    width: calc(100% - 40px);
    background-color: white;
    min-height: 30px;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-top: -4em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    padding: 10px;
}
.circleInfoTextBoxDesktop{
    position: absolute;
    left: 35%;
    top: 40px;
    margin-top: 0;
    min-height:240px;
    width: 60%;
    padding: 40px 30px;
    box-sizing: border-box;
}
.circleInfoTextBoxDesktopRight{
    right: auto;
    left: 80px;
}
.circleInfoTitle{
    color: #D65E4D;
    font-size: 30px;
    margin: 0;
}
.circleInfoContent{
    color: black;

}

.circlesInfoBackground{
    padding-bottom: 20px;
}

@media screen and (max-width: 800px){
    .circleInfoTextBoxDesktop{
        width: calc(100% - 60px);
        background-color: white;
        min-height: 30px;
        display: inline-block;
        position: relative;
        z-index: 2;
        margin-top: -4em;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
        padding: 10px;
        left: auto;
        top: auto;
    }
    .circleInfoTextBoxDesktopRight{

    }
    .circleInfoBoxDesktop{
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        justify-content: center;
        /* min-height: 16em; */
        position: relative;
        text-align: left;
        text-align: center;
        display: inline-block;
    }
    .circleInfoBoxDesktop img{
        height: 22rem;
        width: 22rem;
        border-radius: 50%;
        display: inline-block;
        float: none;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 650px){
    .circleInfoBoxDesktop img{
        height: 18rem;
        width: 18rem;
    }
}

/* Quote Box */

.quoteBox{
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../Media/ladyWithPicture.jpg");
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    position: relative;
}   
.quoteBoxDesktop{
    height: 500px;
}
.quoteBox img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.quoteOverlay{
    background-color: #39215b65;
    position: absolute;
    height: 250px;
    width: 100%;
}
.quoteOverlayDesktop{
    height: 500px;
}
.quote{
    position: absolute;
    font-size: 30px;
    font-style: italic;
    color: white;
    width: 60%;
}

/* Directory */

.directoryTitle{
    font-size: 30px;
}
.directoryDescription{
    text-align: justify;
    padding: 20px;
}
.directoryDescriptionDesktop{
    width: 60%;
    margin-left: 20%;
    text-align: center;
}
.directoryTitleSectionDesktop{
    display: inline-block;
}
.contentBox{
    display: inline-block;
    width: calc(100% - 40px);
    margin: 20px;
    background-color: white;
    vertical-align: top;
    padding: 10px;
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;   
    max-width: 500px;
} 
.contentBoxDesktop{
    width: 40%;
    min-height: 240px;
}
.contentBoxTitle{
    color: #D65E4D;
    font-size: 26px;
    font-weight: bold;
}
.contentBoxText{
    color: black;
    font-size: 14px;
    text-align: justify;
    margin-bottom: 40px;
    padding: 0 10px;
}
.contentBoxButton{    
    padding: 10px 20px;    
    background-color: #D65E4D;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 500ms;
    display: inline-block;    
    font-size: 16px;
    position: absolute;
    bottom: 10px;
    width: 90%;
    left: 5%;
    box-sizing: border-box;
}

/* Bottom Bar */

.bottomBar{
    width: 100%;
    height: auto;
    position: relative;
    background-color: #351563;
}
.bottomBarColumn{
    width: 50%;
    padding: 20px 10px;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    color: white;
}
.bottomLink{
    padding: 10px;
    color: white;
}
.bottomLink:hover{
    background-color: rgba(128, 0, 128, 0.456);
}

/* Topnav Placeholder (depreciated) */

.topNavPlaceholder{
    position: fixed;
    top: 0;
    height: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 3;
}
.topNavPlaceholderTop{
    top: 0;
    bottom: auto;
}
.topNavPlaceholderBottom{
    top: auto;
    bottom: 0;
}