.menuWindow{
    position: fixed;
    z-index: 10;
    width: 90%;
    left: 50%;
    transform: translateX(-50%); 
    max-width: 400px;
    top: 100px;
    text-align: center;
    padding-bottom: 40px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0 5px 10px black;
}
.menuButton{
    width: 95%;
    display: inline-block;
    border-radius: 5px;
    font-size: 20px;
    transition: 500ms;
    padding: 10px 20px;
    background-color: rgb(235, 241, 247);
    margin-top: 10px;
    border: 1px solid rgb(61, 138, 253);
    box-sizing: border-box;
    color: black;
}
.menuButton:hover{
    background-color: rgb(223, 223, 223);
}
.menuTitle{
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid black;
    font-size: 30px;
}
.imageViewerWindow{
    position: absolute;
    z-index: 5;
    height: 80%;
    width: 98%;
    bottom: 10%;
    left: 1%;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0 5px 10px black;
    padding: 5px;
    box-sizing: border-box;
}
.imageViewerWindow img{
    height: calc(100% - 20px);
    width: 100%;
    object-fit: contain;
}












/* Filter */

.filterBox{
    position: fixed;
    left: 0;
    width: 280px;
    height: 100vh;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: 1000ms;
    z-index: 3;
}
.fade{
    opacity: 0.0;
}
.filterBoxClosed{
    width: 50px;
}
.filterBoxClosedMobile{
    width: 40px;
}
.filterRow{
    border-bottom: 1px solid rgba(167, 167, 167, 0.836);
    position: relative;
    padding: 10px;
    min-height: 20px;
    text-align: left;
}
.filterRowButton{
    width: calc(100% - 20px);
    color: white;
    background-color: rgb(88, 127, 255);
    background-image: none;
}
.filterRowButton:hover{
    width: calc(100% - 20px);
    background-color: rgb(134, 162, 255);
    background-image: none;
}
.filterRowImage{
    height: 30px;
    width: 30px;
    display: inline-block;
}
.filterRowTitle{
    display: inline-block;
    vertical-align: top;
    margin: 5px 10px;
    font-weight: 600;
    transition: 1000ms;
}
.filterRowOpenButton{
    position: absolute;
    right: 0;
    top: 5px;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    user-select: none;
    font-size: 25px;
}
.filterRowInner{
    padding: 15px 0;
}
.filterRow select{
    width: calc(100%  - 30px);
}
.filterBoxTop{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 120px;
    position: relative;
}
.filterBoxTopMobile{
    height: 60px;
}
.filtersTitle{
    font-size: 26px;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    transition: 1000ms;
}
.filtersTitleRotated{
    transform: rotate(90deg);
    /* padding-bottom: 200px; */
    /* top: 100px; */
    padding-bottom: 0px;
}
.filtersTitleRotatedInner{
    position: relative;
    /* background-color: orange; */
    padding-left: 100px;
}
.filterBarButton{
    position: absolute;
    right: 10px;
    top: 80px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    user-select: none;
}
.filterBarButtonMobile{
    right: 5px;
    top: 15px;
}
.filtersBox{
    padding: 10px;
    transition: 1000ms;
    height:calc(100% - 160px);
    overflow-y: auto;
}
.ageFilterBox{
    display: inline-block;
}
.filterCheckRow{
    font-size: 18px;
    line-height: 25px;
    padding: 5px 10px;
}
.checkBox{
    height: 25px;
    width: 25px;
    margin: 0;
}
.filterCheckRowText{
    padding-left: 20px;
    display: inline-block;
}
/* Image Uploader */

.imageArea{
    width: 100%;
    display: inline-block;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    vertical-align: top;
    text-align: center;    
}
.mainImage{
    height: 220px;
    width: 220px;
    border: 1px solid black;
    display: inline-block;
    margin: 10px;
    vertical-align: top;
}
.secondaryImage{
    height: 100px;
    width: 100px;
    border: 1px solid black;
    display: inline-block;
    margin: 10px;
}
.sideImages{
    display: inline-block;
    width: 130px;
}
.imageUploader{
    height: 100%; 
    width: 100%;
    position: relative;
    z-index: 0;
}
.imageUploader label{
    height: 100%;
    width: 100%;
    background-color: green;
}
.progressBar{
     position: absolute;
     bottom: 5px;
     width: 90%;
     margin-left: 5%;
     z-index: 2;
     background-color: rgba(12, 110, 221, 0.37);
     border-radius: 3px;
     border: 1px solid rgb(4, 61, 128);
}
.progressBarInner{
    height: 100%;
    background-color: rgba(12, 110, 221, 1);
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.imageDropZoneMessage{
    position: absolute;
    z-index: 2;
    font-size: 12px;
    display: inline-block;
    width: 100%;    
    text-align: center;
    top: 40px;
}

/*  */

.bottomButtons{
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.buttonHalf{
    width: calc(50% - 20px);
}
.bottomButton{
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 5px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
}
.bottomButton img{
    height: 100%;
    width: 100%;
}
.bottomButtonLeft{
    left: 5px;
    color: red;    
}
.bottomButtonRight{
    right: 5px;
}

.imageSelectorLabel{
    height: 100%;
    width: 100%;
}
.imageDropZone{
    height: 100%;
    width: 100%;
    display: flex;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.24);
    text-align: center;
    position: relative;
}
.imageDropZone img{
    width: 100%;
    object-fit: cover;
}

.imageViewerButton{
    position: absolute;
    top: calc(50% - 20px);
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    transition: 500ms;
    user-select: none;
}
.imageViewerButton:hover{
    background-color: rgba(128, 128, 128, 0.2);
}
.imageViewerButtonRight{
    right: 10px;
}
.imageViewerButtonLeft{
    left: 10px;
}
.imageViewerMessage{
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;  
    text-align: center;  
}
.newMessageCircle{
    position: absolute;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    border: 2px solid rgb(255, 115, 0);
    color: orange;
    background-color: rgb(255, 255, 255);
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    height: 25px;
    width: 25px;
    box-shadow: 0 0 10px gold;
    transition: 500ms;
    text-align: center;
}
.newMessageCircleLarge{
    box-shadow: 0 0 80px gold;
    border: 20px solid rgb(255, 196, 0);
    top: -60px;
    left: -60px;

}
.window{
    position: absolute;
    display: inline-block;
    height: 180px;
    width: 300px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    top: 100px;
    left: calc(50% - 150px);
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
}
.confirmationWindow{
   z-index: 80;
   position: fixed;
}
@media screen and (max-width: 400px) {
    .mainImage{
        height: 180px;
        width: 180px;
        display: inline-block;
    }
    .viewImageArea{
        width: 100%;
    }
    .sideImages{
        /* display: block; */
        /* width: 100%; */
        width: 100px;
    }
    .secondaryImage{
        display: inline-block;
        width: 80px;
        height: 80px;
    }
    .widthHide{
        /* display: none; */
    }
}
@media screen and (max-width: 330px) {
    /* .mainImage{
        height: 100px;
        width: 100px;
        display: inline-block;
    } */
    .viewImageArea{
        width: 100%;
    }
    .sideImages{
        display: block;
        width: 100%;
    }
    .secondaryImage{
        display: inline-block;
  
    }
    .widthHide{
        display: none;
    }
}