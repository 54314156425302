




/* Chat Window */

.chatWindow2{
    height: calc(100vh - 160px);
    width: 90vw;
    top: 80px;
    border: 1px solid black;
    padding: 0;
    overflow: hidden;
}
.chatWindowMobile{
    width: 100vw;
    height: calc(100vh - 60px);
    bottom: 0;
    top: auto;
}
.chatBarContainer2{
    height: 100%;
    width: 300px;
    padding-bottom: 60px;
    box-sizing: border-box;
    display: inline-block;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    position: relative;
}
.chatBarContainerMobile{
    width: 100vw;
}
.chatWindowCloseEditButton{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.chatContainer2{
    display: inline-block;
    width: calc(100% - 300px);
    height: 100%;
    vertical-align: top;
}
.chatContainerMobile{
    width: 100vw;
}
/* Chat Bar */

.chatBarWithBottomButton{
    padding-bottom: 60px;
}

.chatBarImageButton{
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.chatBarImageButtonMoblie{
    width: 60px;
    right: 10px;
    text-align: center;
    height: 50px;
    position: absolute;
}
.chatBarImageButtonMoblie2{
    right: 70px;
}
.chatBarImageButtonMoblieImgContainer{
    width: 30px;
    height: 50px;
    display: inline-block;
}
.chatBarButtonLabeled{
    height: 60px;
}

/* Image Button With Label */

.chatBarSearch{
    width: 100%;
    height: 40px;
    margin-top: 20px;
    position: relative;
}
.chatBarSearch input{
    border-radius: 50px;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    box-shadow: none;
    box-sizing: border-box;
    padding-left: 45px;
    background-color: rgb(230, 230, 230);
}
.searchImage{
    height: 30px;
    width: 30px;
    position: absolute;
    left: 15px;
    top: 1px;
    display: flex;
    padding-top: 8px;
}
.searchImage img{
    height: 80%;
    width: 80%;
    object-fit: cover;    
}
.chatBarsArea{
    overflow-y: auto;
    /* max-height: calc(100vh - 290px);  */
    height: 100%;
    /* height: calc(100vh - 190px);  */
    /* height: 100%; */
    /* height: 100%;    */
    /* padding-bottom: 80px; */
}
.chatBarsAreaShorter{
    max-height: calc(100vh - 340px);
    box-sizing: border-box;
}
.chatBarBottomButton{
    height: 60px;
    text-align: center;
    width: 100%;
    color: rgb(47, 130, 255);
    line-height: 60px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
}
/* Chat Bar Chat */

.chatBarChat{
    padding: 5px;
    /* width: calc(100% - 15px); */
    height: 60px;
    /* background-color: rgb(250, 250, 250); */
    background-color: white;
    cursor: pointer;
    transition: 500ms;
    position: relative;
    border-radius: 10px;
    font-weight: 600;
    margin: 10px 0px;
}
.chatBarChat:hover{
    background-color: rgb(235, 235, 235);
}
.selectedChatBarChat{
    background-color: rgb(201, 229, 255);
}
.selectedChatBarChat:hover{
    background-color: rgb(177, 216, 253);
}
.chatBarImage{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    left: 5px;
    top: 5px;
    background-image: radial-gradient(rgba(237, 251, 255, 0.216), rgba(192, 227, 255, 0.742));
    background-image: radial-gradient(rgb(255, 255, 255),rgba(254, 227, 241, 0.626), rgb(255, 196, 227));
    background-image: radial-gradient(rgb(255, 255, 255),rgb(215, 215, 215));
}
.chatBarImage img{
    height: 120%;
    width: 120%;
    object-fit: cover;
}
.chatBarLine{
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: calc(100% - 90px);
    text-align: left;
    overflow: hidden;
}
.chatBarMessagePreview{
    top: 35px;
    height: 20px;
    overflow: hidden;
    font-weight: 400;
}

/* Chat */



/* Chat Messages */
.chatMessages{
    height: 100%;
}
.chatMessageOuter{
    width: 100%;
    text-align: left;
    margin: 10px 0;
}
.chatMessageOuterLeft{
    width: 100%;
    text-align: left;
}
.chatMessageOuterRight{
    width: 100%;
    text-align: right;
}
.chatMessage{
    max-width: 70%;
    border-radius: 20px;
    text-align: left;
    padding: 10px 20px;
    box-sizing: border-box;
    padding-top: 14px;
    padding-bottom: 15px;
    font-size: 18px;
    display: inline-block;
    font-size: 16px;

}
.chatMessageRight{
    background-color: rgb(47, 130, 255);
    color: white;
}
.chatMessageLeft{
    background-color: rgb(230, 230, 230);
    color: black;
}
.messageUserImage{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;
    vertical-align: bottom;
    background-image: radial-gradient(rgb(255, 255, 255),rgb(215, 215, 215));

}
.messageUserImage img{
    height: 100%;
    width: 100%;
}

/* Chat Input */


.chatInputSend{
    height: 40px;
    width: 40px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    vertical-align: bottom;
    margin-bottom: 8px;
}
.chatInputSend img{
    width: 100%;
    height: 100;
}
.chatMessageFloating{
    max-width: calc(80% - 80px);
}
.chatOpenWindowButton{
    position: absolute;
    top: 15px;
    right: 40px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 5px;
}
.chatUserImage{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    z-index: 2;
    background-image: radial-gradient(rgb(255, 255, 255),rgb(215, 215, 215));
}
.chatUserImage img{
    height: 120%;
    width: 120%;
    background-color: rgba(200, 200, 200, 0.5);
}
.chatUserName{
    height: 100%;
    width: calc(100% - 70px);
    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
    padding-left: 10px;
    overflow: hidden;
    box-sizing: border-box;
}


/* FLoatin Chat Window */

.floatingChatWindow{
    position: fixed;
    right: 20px;
    bottom: 0px;
    height: 500px;
    width: 400px;
    background-color: white;
    border-radius: 10px 10px 0 0;
    z-index: 10;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.floatingChatMinimized{
    height: 70px;
}
.floatingChatWindow2{
    right: 440px;
}
.floatingChatWindow3{
    right: 860px;
}
.floatingChatWindowOverflow{
    display: none;
    right: 10px;
    bottom: 10px;
    height: 60px;
    width: 60px;
}
/* New Chat Window */
.newChatTitle{
    padding: 10px;
    font-weight: 600;
    /* font-size: 14px; */
    text-align: left;
}
.newChatToInputOuter{
    /* background-color: green; */
    width: 100%;
    line-height: 40px;
    text-align: left;
    padding-left: 20px;
    padding: 10px;
    padding-left: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    /* margin-bottom: 10px; */
}
.newChatToInput{
    outline: none;
    border: none;
    box-shadow: none;
    background-color: white;
    /* background-color: orange; */
    margin: 0;
    width: calc(100% - 40px);
    font-size: 18px;
}
.newChatUsers{
    height: calc(100vh - 160px);
    overflow-y: auto;
}
.userSearchResult{
    text-align: left;
    transition: 500ms;
    cursor: pointer;
}
.userSearchResult:hover{
    background-color: rgba(202, 202, 202, 0.4)
}
.userSearchResultImage{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin: 5px;
    margin-left: 20px;
}
.userSearchResultImage img{
    
}
.userSearchResultName{
    display: inline-block;
    vertical-align: top;
    margin: 15px 10px;
}


.unreadChat{
    background-color: rgb(238, 238, 247);
}
.unreadChatMessage{
    font-weight: 700;
    /* color: orange; */
    /* position: relative; */
}
.unreadIndicator{
    position: absolute;
    right: 10px;
    background-color: rgb(19, 113, 255);
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 0;
}