.screeningPageOuter{
    background-color: rgb(245, 245, 245);
    /* background-color: rgb(0, 162, 255); */
    min-height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}
.screeningPage{
    /* 800 */
    width: 600px;
    width: 800px;
    /* 1035 scale text accordingly or zoom to 133% */
    height: 776px;
    height: 1100px;
    background-color: white;
    margin: 20px 0;
    display: inline-block;
    font-size: 14px;
    }
.screeningTables{
    padding-top: 80px;
}
.flexRow{
    display: flex;
    /* justify-content: space-between; */
}
.flexRowBetween{
    display: flex;
    justify-content: space-between;
}
.left{
    text-align: left;
}
.pad{
    padding: 10px 20px;
}
.lightBlueText{
    color: rgb(147, 221, 255);
    color: rgb(0, 162, 255);
}
.blueText{
    color: rgb(0, 162, 255);

}
.block{
    display: block;
}
.screeningLogo{
    width: 140px;
    padding: 5px;
    box-sizing: border-box;
}
.screeningLogo img{
    width: 100%;
    object-fit: contain;
}
.screeningTitle{
    font-size: 20px;
    padding-bottom: 5px;
}
.bold{
    font-weight: bold;
}
.blueBar{
    background-color: rgb(0, 162, 255);
    width: 100%;
    height: 30px;
    color: white;
    text-align: left;
    padding-left: 20px;
    line-height: 30px;
    font-size: 16px;
}
.greyBar{
    text-align: center;
    background-color: rgb(241, 241, 241);
    color: black;
    font-weight: 500;
}
.rowHalf{
    width: 50%;
    display: inline-block;
}
.widthLarger{
    width: 60%;
}

.inlineBlock{
    display: inline-block;
}
.screeninTableRow{
    width: 50%;
    padding: 3px 10px;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
}
.widthShorter{
    width: 40%;
}
.grey{
    background-color: rgb(241, 241, 241);
}
.table{
    border: 2px solid rgb(241, 241, 241);
}
.date{
    padding-top: 10px;
    box-sizing: border-box;
}
.officeUseBox{
    border: 1px solid black;
}