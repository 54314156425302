/* File Uploader */
.fileUploader{
    height: 100%; 
    width: 100%;
    position: relative;
    z-index: 0;
    border: 1px solid black;
    min-height: 200px;
}
.fileUploadDropZone{
    height: 200px;
    width: 100%;
    display: flex;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.24);
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* File Preview */
.fileUploadPreview{
    width: calc(100% - 20px);
    margin: 10px;
    height: 600px;
}
.fileUploaderButtons{
    padding-bottom: 10px;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
}
.fileUploaderOptionButton{
    border-radius: 0;
    background-color: rgb(210, 210, 210);
    padding: 5px 10px;
    width: 200px;
}

/* Progress Bar */
.progressBar{
     position: absolute;
     bottom: 5px;
     width: 90%;
     margin-left: 5%;
     z-index: 2;
     background-color: rgba(12, 110, 221, 0.37);
     border-radius: 3px;
     border: 1px solid rgb(4, 61, 128);
     text-align: center;

}
.progressBarInner{
    height: 100%;
    background-color: rgba(12, 110, 221, 1);
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
