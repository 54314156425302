.dbInput{
    width: calc(50% - 10px);
    margin: 5px;
    display: inline-block;
    text-align: left;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
}
.dbInputFullWidth{
    width: calc(100% - 10px);
}
.dbInputLabel{
    padding-bottom: 5px;
    font-size: 12px;
}
.dbInputInput{
    width: 100%;
    margin: 0;
    border-radius: 0;
}
.dbInputReview{
    width: calc(100% - 35px);
    margin: 0;
    overflow: hidden;
}
.dbInputTextArea{
    width: 100%;
    margin: 0;
    min-height: 80px;
}
.dbInputReviewEdit{
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}