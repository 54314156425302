/* 
  Z index values: 

  menuWindow 5
  navbar 5

  profileViewWindow 4

  filterBar 3

  searchResultsTopBar 2

*/

.App {
  height: 100%;
  width: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: 500ms;
  text-align: center;
  overflow: hidden;
}
.AppMobile{
  max-width: calc(100vw);
}

.appInner{
  height: 90%;
  width: 90%;
  position: absolute;
  bottom: 5%;
  left: 5%;
  background-color: rgba(128, 128, 128, 0.75);
  overflow: hidden;
  border: 2px solid black;
}
.appInner2{
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  /* background-color: rgb(147, 221, 255); */
  overflow: hidden;
}
.appInner2Desktop{
  padding-top: 50px;
}
.appScroller{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.spacer{
  height: 2000px;
  /* background-color: rgba(0, 0, 255, 0.411); */
  /* background-image: linear-gradient(rgb(87, 172, 66), rgb(142, 66, 187)); */
}
input {
  outline: none;
  border: none;
  padding: 8px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8) inset;    
  /* margin: 10px; */
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% - 20px);
}
textarea {
  outline: none;
  border: none;
  padding: 5px 10px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8) inset;    
  margin: 10px;
  vertical-align: top;
}
select {
  outline: none;
  border: none;
  padding: 8px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8) inset;    
  /* margin: 10px; */
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% - 20px);
}
button{
  width: 30%;
  padding: 5px 10px;
  background-color: rgb(243, 243, 243);
  background-image: linear-gradient(rgba(128, 128, 128, 0), rgba(201, 201, 201, 0.699));
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  outline: none;
  border: none;
  margin: 10px;
  transition: 500ms;
  cursor: pointer;
}
button:hover{   
  background-color: rgb(250, 250, 250);     
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  outline: none;
  border: none;
  margin: 10px;
}
a{
  text-decoration: none;
  color: black;
}
pre{
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  max-width: 100%;
  margin: 0;
}
.checkBox{
  height: 25px;
  width: 25px;
  margin: 0;
}

.heightDisplay{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 200px;
  background-color: orange;
}

.padForNavbar{
  padding-top: 50px;  
  max-height: calc(100vh);
}

.imageButtonPosition{
  position: absolute;
  top: 10px;
  right: 0px;
}
.imageButtonPosition2{
  position: absolute;
  top: 10px;
  right: 60px;    
}
.imageButtonSize{
  height: 50px;
  width: 50px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.imageButtonImage{
  height: 40px;
  width: 40px;
  padding: 0px;
  display: inline-block;
}
.imageButtonImage2{
  height: 30px;
  width: 30px;
  padding: 0px;
  display: inline-block;
}
.imageButtonImage img{
  object-fit: contain;
  /* width: calc(100% - 10px); */
}
.imageButtonLabel{
  font-size: 8px;
  width: 100%;
  position: absolute;
  bottom: 5px;
  font-weight: normal;
}

.closeButton{
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  background-color: rgb(243, 125, 125);
  border-radius: 3px;
  cursor: pointer;
  color: white;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  transition: 500ms;
  line-height: 16px;
  z-index: 2;
  text-align: center;
}
.hidden{
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
}
.smallText{
  font-size: 8px;
}
.centered{
  width: 100%;
  text-align: center;
}
.width100{
  width: 100%;
}
.border{
  border: 1px solid black;
}





/* Scroll */
.scroleSection{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.spacer{
  height: 2000px;
}

.mobileWindow{
  position: absolute;
  top: 0;
  z-index: 4;
  left: 0;
  width: 100vw;
  width: 100%;
  background-color: white;
}

/* Tag Default Styling */
div{
  box-sizing: border-box;
}
img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  outline: none;
  border: none;
}
input {
  outline: none;
  border: none;
  padding: 8px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8) inset;    
  /* margin: 10px; */
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% - 20px);
}
textarea {
  outline: none;
  border: none;
  padding: 5px 10px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4) inset;    
  margin: 10px;
  vertical-align: top;
}
button, select, input{
  border: none;
  outline: none;
  padding: 8px 20px;
  box-sizing: border-box;
  vertical-align: top;
  transition: 500ms;
  /* margin: 10px 5px; */
}
button, select{
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
}
select, input{
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, .4);  
}
button{
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  background-color: #fc0;
  font-weight: 600;
  font-size: 12px;
}
button:hover{   
  background-color: rgb(254, 237, 170);
  box-shadow: 0 0 6px #fc0;
}
a{
  text-decoration: none;
  color: black;
}
pre{
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  max-width: 100%;
  margin: 0;
  font-family: sans-serif;
}
.checkBox{
  height: 25px;
  width: 25px;
  margin: 0;
}

/*  */

.title{
  font-size: 28px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.title2{
  font-size: 22px;
}
.logoImage{
  width: 180px;
  object-fit: contain;
}
.topnavOffset{
  padding-top: 80px;
}
.blue{
  background-color: blue;
}
.mL20{
  margin-left: 20px;
}

/*  */
.cornerButton{
  position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  width: 50px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  transition: 500ms;
  line-height: 25px;
  z-index: 2;
}
.closeButton{
  height: 20px;
  width: 20px;
  line-height: 16px;
  background-color: rgb(243, 125, 125);
  color: white;
}
.hidden{
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
}
.smallText{
  font-size: 8px;
}
.smallText2{
  font-size: 12px;
  margin-top: 10px;
}
.inlineLink{
  color: #2d0ab8;
  text-decoration: underline;
  cursor: pointer;
}
.pad20Vertical{
  padding: 20px 0;
}
.centered{
  width: 100%;
  text-align: center;
}
.width50{
  width: 50%;
  display: inline-block;
}
.width100{
  width: 100%;
}
.width80{
  width: 80%;
}
.width70{
  width: 70%;
}
.width50{
  width: 50%;
}
.widthMinus340{
  width: calc(100% - 340px);
}
.border{
  border: 1px solid black;
}
.inlineBlock{
  display: inline-block;
}
.roundedBox{
  width: 300px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}
.textAlignLeft{
  text-align: left;
}
.floatRight{
  float: right;
}
.alignTop{
  vertical-align: top;
}
.alignLeft{
  text-align: left;
}
.center{
  text-align: center;
}
.borderBox{
  border-radius: 8px;
  border: 1px solid rgb(37, 87, 167);
  display: inline-block;
  padding: 15px;
  margin: 10px;
  text-align: left;
  vertical-align: top;
}

/* Page */

.pageInnerWidth{
  width: 90%;
  min-height: 100px;
  display: inline-block;
  max-width: 1000px;
  overflow: hidden;
}
.pageSection{
  margin-top: 40px;
  min-height: 320px;
}
.pageHeight{
  min-height: calc(100vh - 300px);
  position: relative;
} 
.pageHeightScroll{
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.scroll100{
  max-height: 100vh;
  overflow-y: auto;
}
.scroll1k{
  max-height: 1000px;
  overflow-y: auto;
}
.marginBottom10{
  margin-bottom: 10px;
}

@media screen and (max-width: 850px){
  .pageInnerWidth{
    width: 98%;
  }
  .mobile100{
    width: 80%;
    margin: 0 8%;
    text-align: center;
  }
}
@media screen and (max-width: 600px){
  .mobile100{
    width: 98%;
    margin-left: 1%;
  }
}