.mobileNavbar {
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 6;
    bottom: 0px;
    left: 0;
    background-color: white;
    transition: 1000ms;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
}

.mobileNavbar img{
    width: 30px;
    height: 30px;
    background-color: rgb(20, 94, 254);
}

.mobileNavbarTop {
    bottom: auto;
    top: 0px;
}

.mobileTopNavButton {
    position: relative;
}

.mobileTopNavButtonLabel {
    font-size: 8px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    text-align: center;
}

.mobileTopNavLinkImg {
    width: 30px;
    height: 30px;
    background-color: rgb(20, 94, 254);
}

.mobileRoundImage {
    border-radius: 50%;
    object-fit: cover;
    height: 30px;
    width: 30px;
}

.mobileNewMessageCircle {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileNewMessageCircleLarge {
    width: 25px;
    height: 25px;
    font-size: 12px;
}

