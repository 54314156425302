/* Chat Selector Window */
.chatWindowOuter{
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    /* background-color: aqua;
    z-index: 6; */
    /* transition: 500ms; */
}
.chatWindow{
    /* background-color: rgb(183, 214, 228); */
    background-color: white;
    width: 100%;
    height: 100%;
    transition: 1000ms;
    position: absolute;
    bottom: 0;
    z-index: 6;
}
.chatWindowMinimized{
    height: 0;
}

.chatBar{
    height: 100%;
}
.chatBarTitle{
    padding: 8px;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid rgb(216, 216, 216);
    min-height: 48px;
}
.chatBarImageButton{
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

/* Chat */

.chatTopBar{
    width: 100%;
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    /* background-color: orange; */
}
.chatMessagesArea{
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 120px);
    padding: 10px;
    box-sizing: border-box;
}

.chatUserInfo{
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 40px;
    cursor: pointer;
    height: 60px;
    max-width: calc(100% - 10px);
    width: calc(100% - 120px);
    background-color: rgba(228, 232, 236, 0.609);
}
.chatUserInfo:hover{
    background-color: rgb(243, 243, 243);
}

.chatInput{
    width: 100%;
    text-align: left;
    background-color: rgb(250, 250, 250);
    position: absolute;
    bottom: 0;
    z-index: 12;
}
.chatInput textarea{
    width: calc(100% - 80px);
    border-radius: 20px;
    background-color: rgb(230, 230, 230);
    box-shadow: none;
    box-sizing: border-box;
    height: 40px;
    padding-left: 20px;
    padding-top: 9px;
    resize: none;
    line-height: 16px;
    font-size: 14px;
}

/* Emojis Window */
.emojiChatBarButton{
    position: absolute;
    right: 80px;
    /* background-color: orange; */
    bottom: 16px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.emojiWindow{
    height: 190px;
    width: 240px;
    overflow-y: auto;
    border: 1px solid black;
    background-color: white;
    z-index: 40;
    border-radius: 10px;
    padding: 15px;
    position: absolute;
    right: 5px;
    bottom: 50px;
    /* padding-top: 25px; */
    box-sizing: border-box;
}
.emojiSelector{
    display: inline-block;
    transition: 500ms;
    cursor: pointer;
    height: 20px;
    width: 20px;
    padding: 5px;
    border-radius: 3px;
}
.emojiSelector:hover{
    display: inline-block;
    background-color: rgb(218, 218, 218);
}

.adminChatPage{
    width: 400px;
    background-color: rgb(211, 242, 255);
    margin: 20px;
    box-shadow: 0 5px 10px black;
    padding: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.adminChatButtons{
    width: 400px;
    background-color: white;
    display: inline-block;
    margin-bottom: 10px;
}
.adminChatButtons button{
    width: calc(33% - 20px);
    box-sizing: border-box;
}
.adminChatUserTitle{
    background-color: white;
    width: 200px;
    display: inline-block;
}
.adminChatBoxArea{
    width: 400px;
    height: 600px;
    background-color: white;
    display: inline-block;
    position: relative;
    margin-top: 10px;
    position: relative;
}
.adminChatBox{
    height: 100%;
}
.adminChatBoxAbsolute{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    width: 400px;
}
.adminChatUserLink{
    font-size: 14px;
    display: block;
    padding: 5px;
}
.addNoteWindow{
    
}
.addNoteWindow textarea{
    width: calc(100% + 20px);
    margin-left: -10px;
    height: calc(100% - 40px);
}
.iceBreaker{
    width: 90%;
    margin-left: 5%;
    background-color: rgba(78, 202, 254, 0.25);
    border-radius: 10px;
    border: 1px solid rgba(38, 172, 255, 0.3);
    padding: 10px;
    min-height: 80px;
}
.iceBreakerImg{
    display: inline-block;
    height: 60px;
    width: 60px;
    float: left;
}
.iceBreakerTxt{
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: calc(100% - 60px);
}