.applyBox2{
    border: 1px solid black;
    margin: 10px;
    text-align: center;
}
.applyBox2Inner{
    padding: 10px;
}
.applyBox2Title{
    background-color: #fc0;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    box-sizing: border-box;
}
.applicationBox2TopMessage{
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
}
.applyBox2BottomMessage{
    padding: 10px;
    font-size: 12px;
}
.applyBox2ErrorMessage{
    color: rgb(96, 43, 255);
}
.applyBox2Button{
    width: 200px;
}