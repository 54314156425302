.profile{
    height: 100%;
    width: 100%;
}
.profileViewWindow{
    text-align: left;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: white;
    z-index: 5;
    padding-top: 55px;
    padding-bottom: 50px;
    box-sizing: border-box;
}
.profileViewTopBar{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 0;
    border-bottom: 1px solid grey;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.profileSearchBackButton{
    cursor: pointer;
}
.profileViewTopBarBackIcon{
    height: 40px;
    width: 40px;
}
.profileViewTopBarBackText{
    display: inline-block;
    vertical-align: top;
    padding: 5px 20px;
}
.profileBanner{
    height: 180px;
    width: 100%;
    overflow: hidden;
    border-radius: 0;
    background-color: rgba(204, 204, 204, 0.596);
}
.pageTop{
    background-image: linear-gradient(rgba(77, 154, 255, 0.493), rgba(152, 197, 255, 0.493), white, white);
    width: 100%;
}
.pageTopInner{
    width: 100%;
}
.profileTopBar{
    text-align: left;
    position: relative;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}
.profileMainImage{
    height: 120px;
    width: 120px;
    position: absolute;
    left: 10px;
    top: -60px;
    border: 2px solid white;
    background-color: rgb(197, 197, 197);
    border-radius: 50%;
    overflow: hidden;
}
.profileName{
    text-align: left;
    margin-left: 140px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
}
.profileTopButtons{
    position: absolute;
    right: 20px;
    top: 20px;
    margin: 0;
    right: 0;
}
.profileButtons{
    width: 100%;
    border-top: 1px solid rgba(170, 170, 170, 0.445);
    margin-top: 50px;
}
.profileButton{
    height: 40px;
    min-width: 80px;
    display: inline-block;
    /* padding: 20px 10px 10px 10px; */
    padding-top: 10px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    transition: 500ms;
    border-bottom: 2px solid rgba(67, 133, 255, 0);
    vertical-align: bottom;
}
.profileButtonSelected{
    border-bottom: 2px solid rgb(67, 133, 255);
    color: rgb(67, 133, 255);
}









.profilePageWindowTopBar{
    height: 40px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: left;
    border-top: 1px solid grey;

    text-align: left;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 0;
    position: fixed;
    background-color: white;
    z-index: 5;
}

.viewImageArea{
    width: 100%;
    display: inline-block;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    vertical-align: top;
    text-align: center;
}
.profileViewContainer{
    height: calc(100% - 60px);
    min-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
}
.profileViewTextDisplay{
    padding: 10px 20px;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
}
.profileViewTitle{
    font-size: 20px;
    font-weight: 600;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
}
.profileViewBioOuter{
    padding: 0 20px;
    height: calc(100% - 435px);
    min-height: 120px;
}
.profileViewBioOuterMobile{
    padding: 0 20px;
    height: auto;
    min-height: 120px;
}
.profileViewBio{
    /* min-height: 80px;
    max-height: 180px; */
    height: calc(100% - 20px);
    overflow-y: auto;
}
.viewProfileHalf{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
}
.textDisplayRowOuter{
    padding: 0px 20px;
    padding-bottom: 10px;
}
.textDisplayRow{
    width: 100%;
    padding: 5px 0;
    line-height: 30px;
    border-bottom: 1px solid rgb(201, 201, 201);
}
.textDisplayRowElement{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    text-align: left;
}
.textDisplayRowElementMobile{
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.textDisplayLeft{
    text-align: left;
}
.textDisplayRight{
    text-align: right;
}
.profileViewTopName{
    font-size: 30px;
    width: 100%;
    text-align: center;
}
.profileViewChat{
    height: calc(100% - 50px);
    min-height: 200px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    margin-top: 10px;
}
.profileViewButton{
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(rgba(128, 128, 128, 0), rgba(128, 128, 128, 0.26));
    padding: 10px 20px;
    box-sizing: border-box;
    margin:  20px 0 10px 0;
    display: block;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: black
}
.profileViewButton a{
    width: 100%;
    padding: 10px 20px;
}




.profileButtonText{
    display: inline-block;
    padding: 0 10px;
    vertical-align: top;
}
.smallIcon{
    height: 30px;
    width: 30px;
    display: inline-block;
}
.profileButton:hover{
    background-color: rgb(238, 238, 238);
    border-bottom: 2px solid rgb(182, 207, 255);
}

.profileButtonSelected:hover{
    border-bottom: 2px solid rgb(67, 133, 255);
    color: rgb(67, 133, 255);
}
.profileBottom{
    background-color: rgb(236, 236, 236);
    min-height: calc(100% - 360px);
}
.profileBottomInner{
    width: 74%;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
}

.profileTileColumn{
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
}
.profileTileColumnDesktop{
    width: 80%;
    margin-left: 10%;
    display: inline-block;
}
.profileTileColumnDesktopLeft{
    width: 40%;
}
.profileTileColumnDesktopRight{
    width: 60%;
}
.profileTile{
    background-color: white;
    border-radius: 15px;
    width: 100%;
    min-height: 20px;
    padding-bottom: 20px;
    vertical-align: top;
    margin-top: 10px;
    overflow-x: hidden;
}
.profileTileTitle{
    width: 100%;
    text-align: left;
    padding: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid rgb(199, 199, 199);
}
.profileTileInner{
    text-align: left;
    padding: 10px 20px;
    box-sizing: border-box;
}
.profilePageChatContainerOuter{
    height: 400px;
    min-height: 400px;
    padding: 10px;
}
.profilePageChatContainer{
    height: 100%;
    width: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
}
.editProfilePageBio{
    height: 240px;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
}
.profileTile input{
    margin: 0;
}
.accountOptionsButton{
    width: calc(100% - 20px);
    box-sizing: border-box;
    height: 30px;
}
@media screen and (max-width: 1350px) {
    .profileBottomInner{
        width: 100%;
    }
}








.editProfilewindow{
    width: 850px;
}
.editProfilewindowMobile{
    height: 80vh;
    overflow: hidden;
    width: 90vw;
    position: fixed;
    top: 80px;
    padding: 0;
}
.editProfileInner{
    height: calc(100% - 100px);
    overflow-y: auto;
    padding-bottom: 40px;
}
.editPofileTitle{
    margin: 0;
    padding: 0;
    font-size: 25px;
    height: 32px;
}
.editPofileTitleMobile{
    padding: 10px 0;
}
.editProfileAreaTitle{
    font-size: 20px;
}
.flexBox{
    display: flex;
    justify-content: space-around;
}
/* Basic Account Input Area */

.editProfileBio{
    height: 235px;
}
.inputArea{
    width: 40%;
    display: inline-block;
    vertical-align: top;    
    margin-top: 10px;
}
.inputAreaMobile{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.proflieInputHalf{
    width: 50%;
    display: inline-block;
    padding: 0 5px;
    box-sizing: border-box;
}
.proflieInputWhole{
    width: 100%;
    display: inline-block;
    padding: 0 5px;
    box-sizing: border-box;
}
.profileInputField{
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: left;
    margin-bottom: 5px;
}
.profileInputField input{
    width: 100%;
    margin: 0;
}
.profileInputField textarea{
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%;
    padding: 10px;
}
.inputArea input.inputFullWidth{
    width: calc(100% - 60px);
}

/* Create Account Area */
.createAccountsArea{
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 10px;
    text-align: center;
    width: 100%;
    max-width: 800px;
}
.createAccountTitle{
    font-size: 26px;
}
.createAccountText{
    font-size: 14px;
    color: grey;
    margin: 10px;
}
.createAccountsArea input{
    margin: 10px;
    width: 90%;
}
.createAccountButton{
    width: calc(100% - 40px);
    background-color: white;
    background-image: linear-gradient(rgba(128, 128, 128, 0), rgba(209, 207, 207, 0.171));
    padding: 10px;
}
.editProfileButton{
    top: 40px;
    left: -10px;
    cursor: pointer;
}
.dScreeningContainer{
    padding: 20px;
}
.dScreening{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    height: 300px;
    width: 300px;
}

@media screen and (max-width: 1000px){
    .profileTileColumnDesktopLeft{
        width: 50%;
    }
    .profileTileColumnDesktopRight{
        width: 50%;
    }
}
@media screen and (max-width: 800px){
    .profileTileColumnDesktopLeft{
        width: 60%;
    }
    .profileTileColumnDesktopRight{
        width: 40%;
    }
}
@media screen and (max-width: 680px){
    .profileTileColumnDesktopLeft{
        width: 100%;
    }
    .profileTileColumnDesktopRight{
        width: 100%;
    }
}