/*  Tiles Column Display */

.column500{
    width: 440px;
    display: inline-block;
    overflow-y: auto;
    /* height: 100vh; */
}
.positoinsColumn{
    height: calc(100vh);
    overflow-y: scroll;
    padding-bottom: 40px;
    margin-bottom: 20px;
}
.positionColumTitle{
    margin-top: 10px;
    margin-bottom: 5px;
}

/* Position Tile */
.positionTile{
    width: 400px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(118, 140, 149, 0.3);
    transition: 250ms;
    text-align: center;
}
.positionTile:hover{
    /* background-color: rgb(245, 250, 252);
    background-color: rgb(245, 250, 252); */
    box-shadow: 0 3px 6px rgba(118, 140, 149, 0.6);
}
.positionTileTitle{
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}
.hoverUnderline{
    cursor: pointer;
}
.hoverUnderline:hover{
    text-decoration: underline;
}
.positionCompanyName{
    font-size: 18px;
}
.positionDescription{
    padding-top: 20px;
}
.positionSecondaryText{
    color: grey;
}
.positionTags{
    margin-top: 20px;
}
.positionTag{
    background-color: rgb(226, 226, 226);
    border-radius: 3px;
    padding: 5px 10px;
    display: inline-block;
    margin: 5px;
}

/* Display */

.positionDisplay{
    display: inline-block;
    vertical-align: top;
    padding: 20px;
    padding-top: 80px;
    width: calc(100% - 520px);
    margin-left: 30px;
}
@media screen and (max-width: 1200px){
    .positionDisplay{
        width: 90%;
    }
}
.positionDisplayMobile{
    width: calc(100% - 0px);
    margin: 0;
    text-align: center;
    padding-top: 20px;
}
.positionTitle{
    /* text-align: left; */
    font-size: 32px;
}
.positionSubTitle{
    font-size: 20px;
}

.topRowButton{
    border-bottom: 1px solid grey;
    font-size: 20px;
    font-weight: 600;
    left: 0;
    padding: 5px 0;
    /* position: absolute; */
    text-align: center;
    top: 0;
    width: 100%;
}
.topRowButtonIcon{
    height: 40px;
    width: 40px;
}
.topRowButtonText{
    display: inline-block;
    padding: 5px 20px;
    vertical-align: top;
}

.companyOverviewTitle{
    margin-top: 20px;
}
.sectionButton{
    display: inline-block;
    padding: 0 10px 10px 10px;
    cursor: pointer;
    user-select: none;
}
.sectionButtonSelected{
    border-bottom: 2px solid goldenrod;
}
.copmanyOverviewButtons{
    width: 800px;
    border-bottom: 1px solid rgb(180, 180, 180);
    margin-bottom: 20px;
}
.companyOverviewSectionDisplay{
    /* max-width: 1000px; */
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}