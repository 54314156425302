.pageOuter{
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: white;
}
.careersPageTitle{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px black;
    text-align: center;
}
.careersPageInner{
    padding-top: 20px;
    padding-bottom: 120px;
}
.careerBox{
    width: 90%;
    margin: 10px;
    margin-left: 5%;
    padding: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border: 1px solid black;
    text-align: center;
    min-height: 160px;
    position: relative;
}
.careerBoxTitle{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
}
.careerBoxClick{
    padding: 10px;
    text-align: center;
    width: calc(100% - 20px);
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    left: 0;
}
.careerViewWindow{
    text-align: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: white;
    z-index: 4;
    padding-top: 55px;
    padding-bottom: 50px;
    box-sizing: border-box;

}
.careerViewTopBar{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 0;
    border-bottom: 1px solid grey;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.positionView{
    padding: 40px 0;
    width: 95%;
    max-width: 800px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.positionTitle{
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    font-size: 25px;
}
.positionDescription{
    padding: 0 25px;
    box-sizing: border-box;
    white-space: pre-wrap;
}
.positionRange{
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
}

.applyBox{
    width: calc(100% - 10px);
    margin-left: 5px;
    /* padding-bottom: 20px; */
}
.applyBoxTextArea{
    width: calc(100% - 20px);
    height: 200px;
    box-sizing: border-box;
}
.applySubmitButton{
    padding-top: 10px;
    width: calc(100% - 20px);
}
.applySubmitButton button{
    width: 100%;
}
.applyMessage{
    font-size: 16px;
    text-align: center;
}
.applyMessageSubmitted{
    background-color: rgba(50, 230, 50, 0.39);
    border: 1px solid rgb(0, 207, 0);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}
.underMaintance{
    height: 100%;
    width: 100%;
    background-color: rgb(91, 176, 255);
    background-image: linear-gradient(rgb(91, 176, 255), rgb(43, 123, 197));
    color: white;
    text-align: center;
    font-size: 30px;
    padding: 40px;
    box-sizing: border-box;
    padding-top: 80px;
}
.underMaintanceImg{
    height: 100px;
    width: 100px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
}
.applyPageOuter{
    background-color: rgb(195, 231, 255);
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}
.applyPageInner{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(33, 92, 209, 0.517);
}
.applyPageInnerDesktop{
    width: 80%;
    margin-left: 10%;
    margin-top: 40px;
}
.applyPageTitle{
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid grey;
    padding-top: 10px;
    padding-bottom: 15px;
    box-sizing: border-box;
}
.applyPageDescription{
    padding: 40px;
    /* background-color: orange; */
    text-align: center;
}
.contactPageContainer{
    background-image: url("../../Media/holdingHands.jpg");
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 50px;
}
.contactPageCover{
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.contactBoxContainer{
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    box-sizing: border-box;
}
.contactBox{
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    text-align: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.148);
    width: 95%;
    max-width: 500px;
    border-radius: 3px;
}
.contactTitle{
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    border-bottom: 1px solid grey;
    width: calc(100% - 20px);
    margin-left: 10px;
    box-sizing: border-box;
}
.contactText{
    padding: 10px;
    box-sizing: border-box;
    width: calc(100% - 20px);
    margin-left: 10px;
    border-bottom: 1px solid grey;
}
.contactEmailLink{
    color: rgb(203, 195, 249);
}
.contactInput{
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    height: 40px;
    padding-top: 12px;
    margin: 10px;
    margin-bottom: 0;
}
.contactTextArea{
    height: 200px;
    width: calc(100% - 20px);
    box-sizing: border-box;
}
.contactButtonContainser{
    width: 100%;
}
.contactButton{
    width: calc(100% - 20px);
    height: 50px;
    background-color: rgba(255, 255, 255, 0.646);
    color: rgb(43, 62, 97);
    /* font-size: 16px; */
    font-size: 16px;
}
.contactSubmittedMessage{
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 430px;
}
