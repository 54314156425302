.navbar{
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 6;
    bottom: 0px;
    left: 0;
    background-color: white;
    transition: 1000ms;
    
    display: flex;
    align-items: center;

    /* padding: 0 40px; */
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    
    width: 100%;

}
.navbarDesktop{
    height: 40px;
}
.navbarTop{
    bottom: auto;
    top: 0px;
}
.topNavButton{
    position: relative;
}
.topNavButtonDesktop{
    padding: 10px 20px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 200px;
    text-align: center;
}
.topNavButtonDesktop:hover{
    background-color: rgba(255, 166, 0, 0.601);
}
.topNavLinkImgDesktop{
    width: 40px;
    height: 40px;
    margin-right: 20px;
}
.topNavButtonLabel{
    font-size: 8px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    text-align: center;
    /* left: -1px; */
}
.topNavButtonLabelDesktop{
    text-align: center;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
}
.navbar img{
    width: 30px;
    height: 30px;
    background-color: rgb(20, 94, 254);
}
.roundImage{
    border-radius: 50%;
    object-fit: cover;
    height: 30px;
    width: 30px;
}







.navbar{
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 6;
    bottom: 0px;
    left: 0;
    background-color: white;
    transition: 1000ms;
    
    display: flex;
    align-items: center;

    /* padding: 0 40px; */
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    
    width: 100%;
}
.navbarDesktop{
    height: 40px;
}
.navbarTop{
    bottom: auto;
    top: 0px;
}
.topNavButton{
    position: relative;
}
.topNavButtonDesktop{
    padding: 10px 20px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 200px;
    text-align: center;
}
.topNavButtonDesktop:hover{
    background-color: rgba(255, 166, 0, 0.601);
}
.topNavLinkImgDesktop{
    width: 40px;
    height: 40px;
    margin-right: 20px;
}
.topNavButtonLabel{
    font-size: 8px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    text-align: center;
    /* left: -1px; */
}
.topNavButtonLabelDesktop{
    text-align: center;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
}
.navbar img{
    width: 30px;
    height: 30px;
    background-color: rgb(20, 94, 254);
}
.roundImage{
    border-radius: 50%;
    object-fit: cover;
    height: 30px;
    width: 30px;
}