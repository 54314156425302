/* DesktopNavbar.css */

.topNav {
    height: 50px;
    background-color: black;
    background-color: white;
    width: 100%;
    /* padding: 10px; */
    box-sizing: border-box;
    border-bottom: 4px solid #fc0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.logo {
    height: 40px;
    width: 100px;
    text-align: left;
    display: flex;
    align-items: center;
    color: white;
    font-size: 40px;
    margin: 5px;
    display: flex;
    align-items: left;
    position: relative;
    margin-left: 10px;
}

.logo img {
    height: 40px;
    object-fit: contain;
}

.menuIcon {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    border: 2px solid orange; /* Corrected to apply border */
    font-size: 20px; /* Adjusted size to make the icon larger */
    color: orange;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 40px; /* Centers text vertically */
    box-sizing: border-box; /* Ensures proper sizing */
}

.topNavButtons {
    position: absolute;
    top: 10px;
    right: 40px;
}

.topNavButtons a {
    color: white;
    color: black;
    margin: 10px;
    transition: 1000ms;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    padding-bottom: 5px;
}

.topNavButtons a:hover {
    border-bottom: 2px solid #fc0;
}

.topNavButton{
    color: white;
    color: black;
    margin: 10px;
    transition: 1000ms;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    padding-bottom: 5px;
    display: inline-block;
    margin: 0 10px;
    user-select: none;
    cursor: pointer;
}
.topNavButton:hover {
    border-bottom: 2px solid #fc0;
}
.topNavButtonsMenuButtons .topNavButton{
    color: white;
}
.topNavButtonsMenu {
    display: none;   
    position: absolute;
    top: 5px;
    right: 10px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.topNavButtonsMenuButtons {
    position: absolute;
    right: 0px;
    top: 50px;
    width: 100%;
    background-color: black;
    height: 0;
    transition: 1000ms;
    overflow: hidden;
    display: none;
}

.topNavButtonsMenuButtonsOpen {
    height: 380px;
}

.topNavButtonsMenuButtons a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 20px 8px;
    transition: 1000ms;
}
.topNavButtonsMenuButtons a:hover {
    background-color: rgba(145, 145, 145, 0.37);
}

.topNavButtonsMenuButtons .topNavButton{
    color: white;
    text-decoration: none;
    display: block;
    padding: 20px 8px;
    transition: 1000ms;
    border: none;
}
.topNavButtonsMenuButtons .topNavButton:hover {
    background-color: rgba(145, 145, 145, 0.37);
}


@media screen and (max-width: 600px) {
    .topNavButtons {
        display: none;
    }

    .topNavButtonsMenu {
        display: inline-block;
    }

    .topNavButtonsMenuButtons {
        display: inline-block;
    }
}
