.searchResultsTopBar{
    position: absolute;
    top: 0;
    height: 50px;
    background-color: white;
    line-height: 50px;
    border-bottom: 1px solid rgb(211, 211, 211);
    font-size: 20px;
    width: 100%;
    text-align: center;
    padding-left: 40px;
    box-sizing: border-box;
    z-index: 3;
}
.pagedUsersContainer{
    min-height: 100%;
    width: 100%;
    padding-left: 40px;
    padding-top: 50px;
    padding-bottom: 150px;
    box-sizing: border-box;
    background-color: rgb(240, 244, 247);
    position: relative;
}
.pagerButtons{
    position: absolute;
    bottom: 70px;
    width: calc(100% - 40px);
    text-align: center;
}
.rangeButton{
    cursor: pointer;
    user-select: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
    padding: 10px 20px;
    background-color: rgb(219, 219, 219);
    border-radius: 5px;
    transition: 500ms;
}
